<template>
  <div class="layout" :style="colorObject">
    <div class="container">
      <header class="border-bottom">
        <div class="d-flex justify-content-between align-items-center p-2 px-3">
          <router-link class="logo" :to="{ name: 'LiffPreorderProducts' }">
            <img :src="meta.image" />
          </router-link>
        </div>
      </header>
      <router-view style="display: flex; flex-direction: column; flex: 1 1 auto;"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { themeColorFn } from "@/mixins/liff/themeColor";

export default {
  mixins: [themeColorFn({ themeConfigPage: "shop" })],
  data() {
    return {
      isSearching: false,
    };
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
      getModule: "liffModule/getModule",
    }),
    moduleConfig() {
      return this.getModule("liff_checkin").module_config || {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";
@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);

.layout {
  background-color: var(--liff-layout_bg_color);
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-height: 100vh;
  padding: 0;
  font-family: "Noto Sans TC", "Helvetica";
  background-color: var(--liff-content_bg_color);
}

header {
  position: relative;

  .logo {
    max-height: 40px;
    max-width: 40vw;

    img {
      max-height: 40px;
    }
  }
}
</style>
